<template>
  <div class="mask" v-if="visible">
    <div class="dialog">
      <div class="banner" v-if="!isMobile">
        <div class="logo">{{ L("欢迎访问") }}</div>
      </div>

      <div class="content">
        <i class="iconfont icon-close" @click="setVisible(false)"></i>

        <Register
          v-if="active === 'register'"
          @changeActive="changeActive"
          :isAgree="isAgree"
        />
        <Login
          v-if="active === 'login'"
          @changeActive="changeActive"
          :isAgree="isAgree"
        />
        <ForgotPassword
          v-if="active === 'forgot'"
          @changeActive="changeActive"
          :isAgree="isAgree"
        />
        <Footer :active="active" @changeActive="changeActive" />
        <Policy :isAgree="isAgree" @agree="onAgree" />
      </div>
    </div>
  </div>
</template>

<script>
import mobile from "@/mixins/mobile";
import Register from "./register.vue";
import Login from "./login.vue";
import ForgotPassword from "./forgot-password.vue";
import Footer from "./footer.vue";
import Policy from "./policy.vue";

import { mapMutations } from "vuex";
export default {
  mixins: [mobile],
  props: ["visible"],

  components: {
    Register,
    Login,
    ForgotPassword,
    Footer,
    Policy,
  },

  data() {
    return {
      active: "login", // 登陆 注册 忘记密码
      isAgree: false, // 同意用户协议
    };
  },
  methods: {
    ...mapMutations("login", ["setVisible"]),
    changeActive(active) {
      this.active = active;
    },

    onAgree() {
      this.isAgree = !this.isAgree;
    },
  },
};
</script>


<style lang="less" scoped>
.icon-close {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 18px;
  cursor: pointer;
  z-index: 99;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
