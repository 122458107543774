<template>
  <div class="user-agreement">
    <i
      v-if="!agree"
      @click="$emit('agree')"
      class="iconfont icon-checkbox-weixuan"
    ></i>
    <i
      v-else
      @click="$emit('agree')"
      class="iconfont icon-checkbox-xuanzhong"
    ></i>
    <span>{{ L("进行注册表示您已同意") }}</span>
    <router-link to="/news/detail?code=SERVICE" target="_blank">{{
      L("《网站服务条款》")
    }}</router-link>
    <router-link to="/news/detail?code=PRIVACY" target="_blank">{{
      L("《隐私权政策》")
    }}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    isAgree: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    agree() {
      return this.isAgree;
    },
  },
};
</script>


  <style lang="less" scoped>
.user-agreement {
  padding: 0 114px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.user-agreement > .iconfont {
  font-size: 16px;
  margin-right: 4px;
}
.user-agreement a {
  color: rgba(0, 0, 0, 0.4);
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .user-agreement {
    padding: 12px;
    margin-top: 0;
  }
}
</style>