<template>
  <div class="layout">
    <PageHeader v-if="isMobile === false" />
    <MobilePageHeader v-if="isMobile === true" />

    <div class="body">
      <router-view />
    </div>
    <PageFooter v-if="isMobile === false" />
    <MobilePageFooter v-if="isMobile === true" />
  </div>
</template>
<script>
import PageHeader from "./components/page-header";
import MobilePageHeader from "./components/mobile-page-header";
import PageFooter from "./components/page-footer";
import MobilePageFooter from "./components/mobile-page-footer";
import mobile from "@/mixins/mobile";
export default {
  mixins: [mobile],

  components: {
    PageHeader,
    PageFooter,
    MobilePageHeader,
    MobilePageFooter,
  },
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>