<template>
  <div class="page-footer">
    <div class="container">
      <div class="logo">ABOUT US</div>
      <div class="menu">
        <!-- <div class="menu-title">帮助中心</div> -->
        <a href="javascript:;" @click="open('HELP')">
          {{ L("帮助中心") }}
        </a>
        <a href="javascript:;" @click="open('ADVICE')">
          {{ L("意见反馈") }}
        </a>
      </div>
      <div class="menu">
        <a href="javascript:;" @click="open('ACTIVITY')">{{ L("关于活动") }}</a>
        <a href="https://www.sztmas.cn" target="_blank"> {{ L("关于台协") }}</a>
      </div>
      <div class="menu">
        <a href="javascript:;" @click="open('CONTACT')">{{ L("联络我们") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    open(code) {
      this.$router.push("/news/detail?code=" + code);
    },
  },
};
</script>


<style lang="less" scoped>
/* 全局底部版权区 */
.page-footer {
  height: 296px;
  background: #004BB0;
}
.page-footer .container {
  padding-top: 55px;
  display: flex;
  align-items: flex-start;
}
.page-footer .logo {
  font-size: 42px;
  line-height: 42px;
  color: #ffffff;
  font-style: italic;
  font-weight: bold;
  margin-right: 100px;
}
.page-footer .menu {
  display: flex;
  flex-direction: column;
  margin-right: 254px;
}
.page-footer .menu:last-child {
  margin-right: 0;
}
.page-footer .menu-title {
  padding-top: 8px;
  margin-bottom: 33px;
  font-size: 16px;
  color: #ffffff;
}
.page-footer .menu a {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 20px;
}
</style>