import { render, staticRenderFns } from "./policy.vue?vue&type=template&id=295a7e16&scoped=true&"
import script from "./policy.vue?vue&type=script&lang=js&"
export * from "./policy.vue?vue&type=script&lang=js&"
import style0 from "./policy.vue?vue&type=style&index=0&id=295a7e16&lang=less&scoped=true&"
import style1 from "./policy.vue?vue&type=style&index=1&id=295a7e16&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295a7e16",
  null
  
)

export default component.exports