import request from '../request'

export function fetchCode(params) {
    return request({
        url: '/Api/Session/sendCodeMail',
        params,
    })
}
export function register(params) {
    return request({
        url: '/Api/Session/reg',
        params,
    })
}

export function login(params) {
    return request({
        url: '/Api/Session/login',
        params,
    })
}

export function editPassword(params) {
    return request({
        url: '/Api/Session/forget',
        params,
    })
}