import { isMobile } from '@/utils/media'

export default {
    data() {
        return {
            isMobile: null,
        }
    },
    mounted() {
        this.isMobile = isMobile();
    }
}