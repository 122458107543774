<template>
  <div class="register">
    <div class="title">
      {{ L("登入平台") }}
    </div>

    <div class="input-panel">
      <a-icon type="mail" class="icon" />
      <input type="text" :placeholder="L('电子邮箱')" v-model="email" />
    </div>
    <div class="input-panel">
      <a-icon type="lock" class="icon" />
      <input type="password" :placeholder="L('密码')" v-model="password" />
    </div>
    <a-button class="register-button" @click="onLogin" :loading="loading">
      {{ L("登入") }}
    </a-button>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { mapMutations } from "vuex";
export default {
  props: {
    isAgree: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },

  methods: {
    validateEmail() {
      if (!this.isAgree) {
        this.$message.error(window.L("请同意网站服务条款"));
        return true;
      }
      if (!this.email) {
        this.$message.error(window.L("请填写电子邮箱"));
        return true;
      }
      if (!/@/.test(this.email)) {
        this.$message.error(window.L("电子邮箱格式有误"));
        return true;
      }
    },

    validatePassword() {
      if (!this.password) {
        this.$message.error(window.L("请填写密码"));
        return true;
      }
    },

    ...mapMutations("login", ["setVisible", "setResume"]),

    onLogin() {
      if (this.validateEmail()) {
        return;
      }
      if (this.validatePassword()) {
        return;
      }
      this.loading = true;
      login({
        email: this.email,
        password: this.password,
      })
        .then((res) => {
          if (res.token) {
            this.$message.success(this.L("登录成功"));
            window.localStorage.setItem("resume", JSON.stringify(res));
            window.localStorage.setItem("token", res.token);
            window.localStorage.setItem('type', res.type)
            this.setResume(res);
            this.setVisible(false);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 114px;
  position: relative;
}

.title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}

.input-panel {
  display: flex;
  margin-top: 20px;
  height: 55px;
  background: #ffffff;
  border: 1px solid rgba(32, 49, 127, 0.3);
}
.input-panel .icon {
  line-height: 55px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  margin-right: 15px;
}

.input-panel input {
  flex: 1;
  border-width: 0;
  outline: none;

  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 16px;
}
.input-panel input::placeholder {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  opacity: 0.3;
}

.register-button {
  margin-top: 32px;
  height: 55px;
  background: #004BB0;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .register {
    padding: 0 12px;
  }

  .title {
    margin-top: 16px;
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
  }

  .input-panel {
    display: flex;
    margin-top: 24px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(32, 49, 127, 0.3);
  }
  .input-panel .icon {
    line-height: 40px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    margin-left: 15px;
    margin-right: 15px;
  }

  .input-panel input {
    flex: 1;
    border-width: 0;
    outline: none;

    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-right: 16px;
  }
  .input-panel input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: 0.3;
  }

  .register-button {
    margin-top: 16px;
    height: 40px;
    background: #004BB0;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>