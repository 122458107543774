<template>
  <div class="extra-info">
    <div v-if="active === 'register'">
      <span>{{ L("已有账号？") }}</span>
      <a href="#" @click.prevent="$emit('changeActive', 'login')">{{
        L("立即登录")
      }}</a>
    </div>

    <div v-if="active === 'forgot'">
      <span>{{ L("已有账号？") }}</span>
      <a href="#" @click.prevent="$emit('changeActive', 'login')">{{
        L("立即登录")
      }}</a>
    </div>

    <template v-if="active === 'login'">
      <div>
        <span>{{ L("没有账号？点此") }}</span>
        <a href="#" @click.prevent="$emit('changeActive', 'register')">{{
          L("注册实习生账号")
        }}</a>
      </div>

      <div>
        <a href="#" @click.prevent="$emit('changeActive', 'forgot')">{{
          L("忘记密码")
        }}</a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.extra-info {
  padding: 0 114px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}
.extra-info a {
  color: #004BB0;
}
</style>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .extra-info {
    margin-top: 16px;
    padding: 0 12px;
  }
}
</style>