<template>
  <div class="register">
    <div class="title">
      {{ L("注册新用户") }}
    </div>

    <div class="input-panel">
      <a-icon type="mail" class="icon" />
      <input type="text" :placeholder="L('电子邮箱')" v-model="email" />
    </div>
    <div class="input-panel">
      <a-icon type="safety" class="icon" />
      <input type="text" :placeholder="L('认证码')" v-model="code" />
      <div class="code" @click="getCode">{{ L("获取认证码") }}</div>
    </div>
    <div class="input-panel">
      <a-icon type="lock" class="icon" />
      <input
        type="password"
        :placeholder="L('请输入6-20位密码')"
        v-model="password"
      />
    </div>
    <div class="input-panel">
      <a-icon type="lock" class="icon" />
      <input
        type="password"
        :placeholder="L('请再次输入密码')"
        v-model="password2"
      />
    </div>



    <div class="type">
      <input type="radio" value="intern" id="intern" style="margin-right: 4px;" v-model="type">
    <label for="intern" style="margin-right: 8px;">{{ L('在读') }}</label>
    <input type="radio" value="fresh" id="fresh" style="margin-right: 4px;" v-model="type">
    <label for="fresh">{{ L('应届生') }}</label>
    </div>
    <a-button class="register-button" @click="onRegister" :loading="loading">
      {{ L("注册") }}
    </a-button>
  </div>
</template>

<script>
import { fetchCode, register } from "@/api/login";
import { mapMutations } from "vuex";
export default {
  props: {
    isAgree: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: "",
      code: "",
      password: "",
      password2: "",
      type: 'intern',
      loading: false,
    };
  },

  methods: {
    validateEmail() {
      if (!this.isAgree) {
        this.$message.error(window.L("请同意网站服务条款"));
        return true;
      }
      if (!this.email) {
        this.$message.error(window.L("请填写电子邮箱"));
        return true;
      }
      if (!/@/.test(this.email)) {
        this.$message.error(window.L("电子邮箱格式有误"));
        return true;
      }
    },

    validatePassword() {
      if (!this.password) {
        this.$message.error(window.L("请填写密码"));
        return true;
      }
      if (this.password !== this.password2) {
        this.$message.error(window.L("二次密码不一致"));
        return true;
      }
    },

    getCode() {
      if (this.validateEmail()) {
        return;
      }
      fetchCode({
        email: this.email,
      }).then((res) => {
        console.log(res);
        if (res._status === 1) {
          this.$message.success(this.L(res.info));
        }
      });
    },

    ...mapMutations("login", ["setVisible", "setResume"]),

    onRegister() {
      if (this.validateEmail()) {
        return;
      }
      if (this.validatePassword()) {
        return;
      }

      if (!this.type) {
        this.$message.error(window.L("请选择类型"));
        return;
      }

      register({
        email: this.email,
        code: this.code,
        password: this.password,
        type: this.type,
      }).then((res) => {
        if (res.token) {
          this.$message.success(this.L("注册成功"));
          window.localStorage.setItem("resume", JSON.stringify(res));
          window.localStorage.setItem("token", res.token);
          window.localStorage.setItem('type', res.type)
          this.setResume(res);
          this.setVisible(false);
          this.$router.push("/me");
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.register {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 114px;
  position: relative;
}

.title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}

.input-panel {
  display: flex;
  margin-top: 20px;
  height: 55px;
  background: #ffffff;
  border: 1px solid rgba(32, 49, 127, 0.3);
}
.type {
  margin-top: 20px;
  cursor: pointer;
}
.input-panel .icon {
  line-height: 55px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 15px;
  margin-right: 15px;
}

.input-panel input {
  flex: 1;
  border-width: 0;
  outline: none;

  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 16px;
}
.input-panel input::placeholder {
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  opacity: 0.3;
}
.identifying-code {
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-size: 18px;
  font-weight: 400;
  color: #004BB0;
  position: relative;
  cursor: pointer;
}

.register-button {
  margin-top: 32px;
  height: 55px;
  background: #004BB0;
  font-size: 22px;
  font-weight: 400;
  color: #ffffff;
}

.code {
  line-height: 55px;
  padding: 0 15px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .register {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
  }

  .input-panel {
    display: flex;
    margin-top: 16px;
    height: 40px;
    background: #ffffff;
    border: 1px solid rgba(32, 49, 127, 0.3);
  }
  .input-panel .icon {
    line-height: 40px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.3);
    margin-left: 8px;
    margin-right: 8px;
  }

  .input-panel input {
    flex: 1;
    border-width: 0;
    outline: none;

    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    padding-right: 0;
  }
  .input-panel input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: 0.3;
  }
  .identifying-code {
    display: flex;
    align-items: center;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #004BB0;
    position: relative;
    cursor: pointer;
  }

  .register-button {
    margin-top: 32px;
    height: 55px;
    background: #004BB0;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
  }

  .code {
    line-height: 40px;
    padding: 0 8px 0 0;
    cursor: pointer;
    flex-shrink: 0;
  }

  .register-button {
    margin-top: 12px;
    height: 40px;
    background: #004BB0;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>